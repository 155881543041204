import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

const MessageApp = React.lazy(() => import('containers/MessageApp'));
const AppContainer = React.lazy(() => import('containers/AppContainer'));

function Root() {
  return (
    <Router>
      <Switch>
        <Route exact path="/message/:key">
          <Suspense fallback={null}>
            <MessageApp />
          </Suspense>
        </Route>
        <Route path="/">
          <Suspense fallback={null}>
            <AppContainer />
          </Suspense>
        </Route>
      </Switch>
    </Router>
  )
}

export default React.memo(Root);
