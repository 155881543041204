import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'containers/Root';
import * as serviceWorker from './serviceWorker';
import './tw.css';
import Amplify from '@aws-amplify/core';
import aws from 'config/aws';
import smoothscroll from 'smoothscroll-polyfill';

window.__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

Amplify.configure(aws);

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
